<template>
  <div>
    <el-card>
      <Combination :listName="title"></Combination>
      <slot name="header"> </slot>

      <!-- <div class="list-content">
        <div class="list-content-table">
          <el-table
            :data="ListArr"
            border
            style="width: 100%"
            v-loading="Query"
          >
            <template v-if="isWho === 'isUnion'">
              <el-table-column
                prop="id"
                label="工会ID"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                label="公司名称"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="code"
                label="公司账户"
                align="center"
                :formatter="codeFormatter"
              >
              </el-table-column>
              <el-table-column prop="empNo" label="企业信用代码" align="center">
              </el-table-column>
              <el-table-column prop="balance" label="余额" align="center">
              </el-table-column>
              <el-table-column prop="remark" label="备注" align="center">
              </el-table-column>
            </template>
            <template v-if="isWho === 'isGroup'">
              <el-table-column
                prop="groupName"
                label="组名称"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="isActivied"
                label="是否激活"
                width="180"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="stuffName"
                label="组长账号"
                align="center"
                :formatter="codeFormatter"
              >
              </el-table-column>
              <el-table-column prop="remark" label="备注" align="center">
              </el-table-column>
              <el-table-column
                prop="createdDatetime"
                label="创建时间"
                align="center"
              >
              </el-table-column>
            </template>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="cell-edit-union">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="editRow(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="primary"
                    size="mini"
                    @click="deletes(scope.row)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="list-content-pagination">
          <el-pagination
            @size-change="sizeChange"
            @current-change="currentChange"
            :current-page="Pagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="Pagination.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Pagination.total"
          >
          </el-pagination>
        </div>
      </div> -->
    </el-card>
    <!-- <el-dialog
      class="dialogBox"
      :close-on-click-modal="false"
      :title="editTitles"
      :visible.sync="dialogVisibeeEdit"
      top="25vh"
      :show-close="false"
    >
      <edit-union
        :user="sigleuser"
        @closeUnionDialog="canceledit"
        :cancels="cancel"
        :typesc="types"
      ></edit-union>
    </el-dialog> -->
    <el-dialog
      class="dialogBox1"
      :close-on-click-modal="false"
      :title="titles"
      :visible.sync="dialogVisible"
      top="25vh"
      :before-close="closeAddDialog"
    >
      <add-president
        @closePresion="closeAddPresion"
        :types="types"
        :isWhos="isWho"
        :isClean="isClean"
      ></add-president>
    </el-dialog>
  </div>
</template>

<script>
import Combination from '@/components/Combination.vue';
import addPresident from '../laborunion/addPresident';
import EditUnion from '../laborunion/EditUnion';
import { getUnionLists, deleteUnions } from '@/service/moudules/platformApi';
import { getCookie } from '@/util/userLoginFn';
export default {
  components: { Combination, EditUnion, addPresident },
  props: {
    inputValue: {
      type: Object,
      default: () => {},
    },
    Pagination: {
      type: Object,
      default: () => {},
    },
    sigleuser: {
      type: Object,
      default: () => {},
    },
    ListArr: {
      type: Array,
      default: () => [],
    },
    Query: {
      type: Boolean,
      default: false,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    codeFormatter: {
      type: Function,
    },
    sizeChange: {
      type: Function,
    },
    currentChange: {
      type: Function,
    },
    canceledit: {
      type: Function,
    },
    editRow: {
      type: Function,
    },
    deletes: {
      type: Function,
    },
    cancel: {
      type: Function,
    },
    queryDatas: {
      type: Function,
    },
    dialogVisibeeEdit: {
      type: Boolean,
      default: false,
    },
    types: {
      type: Number,
    },
    titles: {
      type: String,
    },
    isWho: {
      type: String,
    },
    isClean: {
      type: Boolean,
    },
    editTitles: {
      type: String,
    },
  },
  data() {
    return {
      getUnionLists,
      getCookie,
      deleteUnions,
      employeeLsit: [],
      sigleuserUnion: {},
      restaurants: [],
      state: '',
      timeout: null,
    };
  },
  methods: {
    closeAddPresion(v) {
      switch (v) {
        case 'isUnion':
          this.$emit('closeUnionDialog');
          break;
        case 'isGroup':
          this.$emit('closeGroup');
          break;
        default:
          break;
      }
    },
    closeAddDialog(done) {
      this.$emit('closeUnionDialog');
      this.$emit('closeGroup');
    },
  },
};
</script>
<style lang="less">
.list-content {
  padding: 20px;
  .list-contetnt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .list-content-select {
      display: flex;
      align-items: center;
      .list-title {
        margin-right: 10px;
        width: 60%;
        font-size: 14px;
        padding: 10px;
      }
    }
    .list-content-btn {
      margin: 0 10px;
    }
  }
  .list-content-table {
    padding: 15px;
  }
  .list-content-pagination {
    display: flex;
    justify-content: flex-end;
  }
}
.cell-edit-union {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
