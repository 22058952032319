<template>
  <div>
    <div class="list-content">
      <div class="list-content-table">
        <el-table
          :data="ListArr"
          border
          style="width: 100%"
          v-loading="loading"
        >
          <el-table-column
            v-for="(item, index) in tableList"
            :prop="item.prop"
            :label="item.name"
            align="center"
            :key="index"
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template slot-scope="scope">
              <div class="cell-edit-union">
                <el-button
                  type="primary"
                  size="mini"
                  width="180"
                  @click="editRow(scope.row)"
                  >修改</el-button
                >
                <!-- <el-button
                  type="primary"
                  size="mini"
                  width="180"
                  @click="deletes(scope.row)"
                  >删除</el-button
                > -->
                <el-button
                  type="primary"
                  size="mini"
                  width="180"
                  @click="use(scope.row)"
                  v-if="isBtn === 'groupBtn' || 'unionBtn'"
                  >{{ scope.row.flag === 0 ? '停用' : '启用' }}</el-button
                >
                <el-button
                  type="primary"
                  size="mini"
                  width="180"
                  @click="editPassword(scope.row)"
                  v-if="editPassword && roles === '会长'"
                  >修改密码</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="list-content-pagination">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="tablePagination.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="tablePagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tablePagination.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import EditUnion from '../laborunion/EditUnion';
export default {
  components: {
    EditUnion,
  },
  props: {
    ListArr: {
      type: Array,
      default: () => [],
    },
    tableList: {
      type: Array,
      default: () => [],
    },
    editRow: {
      type: Function,
    },
    deletes: {
      type: Function,
    },
    use: {
      type: Function,
    },
    tablePagination: {
      type: Object,
      default: () => {},
    },
    editRow: {
      type: Function,
    },
    sizeChange: {
      type: Function,
    },
    editPassword:{
      type: Function,
    },
    currentChange: {
      type: Function,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isBtn: {
      type: String,
    },
  },
  data() {
    return {
      roles:'会长'
    };
  },
};
</script>
<style lang="less">
.list-content {
  padding: 20px;
  .list-contetnt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .list-content-select {
      display: flex;
      align-items: center;
      .list-title {
        margin-right: 10px;
        width: 60%;
        font-size: 14px;
        padding: 10px;
      }
    }
    .list-content-btn {
      margin: 0 10px;
    }
  }
  .list-content-table {
    padding: 15px;
  }
  .list-content-pagination {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
