import { render, staticRenderFns } from "./EditUnion.vue?vue&type=template&id=b08d4786&scoped=true&"
import script from "./EditUnion.vue?vue&type=script&lang=js&"
export * from "./EditUnion.vue?vue&type=script&lang=js&"
import style0 from "./EditUnion.vue?vue&type=style&index=0&id=b08d4786&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b08d4786",
  null
  
)

export default component.exports