<template>
  <div id="editUser">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form
          id="from"
          :model="union"
          ref="ruleForm"
          label-width="100px"
          v-if="typesc === 1"
        >
          <!-- <input type="hidden" v-model="union.id" /> -->
          <el-form-item label="会长名称:">
            <el-select
              v-model="union.unionName"
              placeholder="请选择会长名称"
              class="union-select"
            >
              <el-option
                v-for="(item, index) in nOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="公司名称:">
            <el-input v-model="union.companyName"></el-input>
          </el-form-item>
          <el-form-item label="公司账户:">
            <el-input v-model="union.companyAccount"></el-input>
          </el-form-item>
          <el-form-item label="企业信用代码:">
            <el-input v-model="union.enterpriseCode"></el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="union.remark" type="textarea"></el-input>
          </el-form-item>
        </el-form>

     
      </el-col>
    </el-row>
    <el-row id="anniu22" type="flex">
      <el-col>
        <el-button type="success" @click="canceledits()">取消</el-button>
        <el-button type="primary" @click="submitFormUnion('ruleForm')"
          >确认</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
//启用
import {
  editUser,
  editUnion,
  getEmployeeList,
} from '@/service/moudules/platformApi';
import { getCookie } from '@/util/userLoginFn';
export default {
  created() {
    this.getEmployeeLists();
    this.initDialog(this.user);
  },
  props: ['user', 'cancels', 'typesc'],
  watch: {
    user: {
      async handler(val) {
        this.initDialog(val);
      },
      // immediate: true,
    },
  },
  mounted: function() {
    this.loadCgy();
    // this.getDep();
  },
  data() {
    return {
      getCookie,
      editUnion,
      getEmployeeList,
      nOptions: [],
      value: '',
      union: {
        unionName: null,
        companyName: null,
        companyAccount: null,
        enterpriseCode: null,
        remark: null,
        ids: null,
      },
      group: {
        groupName: null,
        isActivied: null,
        teamLeadId: null,
        remark: null,
      },
      id: '',
      depatment: '',
      depatment1: '',
      depss: [],
      depss1: [],
      orole: '',
      oguid: '',
      roleTree: [],
      groupTree: [],
      refresh: 0,
    };
  },
  methods: {
    //初始化弹框数据
    initDialog(user) {
      switch (this.typesc) {
        case 1:
          var { code, empNo, id, name, remark } = user;
          this.union.enterpriseCode = empNo;
          this.union.companyName = name;
          this.union.companyAccount = code == '' ? null : code;
          this.union.remark = remark;
          this.union.ids = id;
          break;
        case 2:
          var {
            groupName,
            isActivied,
            teamLeadId,
            remark,
            createdDatetime,
          } = user;
          this.group.groupName = groupName;
          this.group.isActivied = isActivied;

          this.group.teamLeadId =
            teamLeadId == ''
              ? null
              : this.nOptions.find((item) => item.label === teamLeadId)
                  .stuffName;
          this.group.remark = remark;
          break;

        default:
          break;
      }
    },
    //获取员工列表
    async getEmployeeLists() {
      try {
        let params = `?pageNo=1&pageSize=200&type=${this.typesc}`;

        const { code, msg, data } = await getEmployeeList(params);
        if (code !== 200 && data.records.length === 0) {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }
        this.nOptions = data.records.map((item, index) => {
          return {
            value: item.stuffId,
            label: item.stuffName,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },

    //确认提交
    async submitFormUnion() {
      try {
        switch (this.typesc) {
          case 1:
            var params = {
              presidentid: this.union.unionName,
              name: this.union.companyName,
              code: this.union.companyAccount,
              empNo: this.union.enterpriseCode,
              remark: this.union.remark,
              id: this.union.ids,
              sessionId: this.getCookie('sessionId'),
            };
            break;
          case 2:
            var params = {
              groupName: this.group.groupName,
              isActivied: this.group.isActivied,
              teamLeadId: this.group.teamLeadId,
              remark: this.group.remark,
              sessionId: this.getCookie('sessionId'),
            };
            break;
          default:
            break;
        }

        let res = await editUnion(params);
        let { data, code, msg } = res;

        if (code !== 200 && msg !== 'success') {
          this.$message({
            type: 'error',
            message: msg,
          });
          return false;
        }

        this.$message({
          type: 'success',
          message: '修改数据成功',
        });
        this.$emit('closeUnionDialog');
      } catch (error) {
        console.log(error, 'error');
      }
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields();
    },
    
    canceledits() {
      this.$emit('closeUnionDialog');
    },
    // async findGroupTree() {
    //   var params = {};

    //   let response = await this.api.platformApi.groupTree(params);
    //   if (response.data.code == 200) {
    //     this.groupTree = response.data.data;
    //   } else {
    //     this.$message.error(response.data.msg);
    //   }
    // },
    // async findGroupRoleTree() {
    //   var params = {
    //     checkedGroups: this.ruleForm.depatments,
    //   };
    //   let response = await this.api.platformApi.groupRoleTree(params);
    //   // console.log(response)
    //   if (response.data.code == 200) {
    //     // console.log(response.data.data)
    //     this.roleTree = response.data.data;
    //   } else {
    //     this.$message.error(response.data.msg);
    //   }
    // },
    // //查询全部的部门树
    // async getGroupTree() {
    //   await this.findGroupTree();
    // },
    // async changeRoleTree() {
    //   console.log(this.ruleForm.depatments);
    //   await this.findGroupRoleTree();
    // },
  },
};
</script>
<style scoped lang="less">
.union-select {
  width: 100%;
}
</style>
